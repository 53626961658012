import "./Glyph.less";
import cx from "clsx";

const Glyph = ({ name, className, style, ...props }) => {
  return (
    <i style={style} className={cx("glyph", className)}>
      {name}
    </i>
  );
};

export default Glyph;
