import React, { useEffect, useRef, useState } from "react";
import {
  Select,
  Row,
  Col,
  DatePicker,
  Card,
  Typography,
  TimePicker,
  Spin,
  InputNumber,
  Input,
  Table,
  Button,
} from "antd";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { range } from "ramda";
import Glyph from "views/Common/Glyph/Glyph";
import useDesignTokens from "hook/useDesignTokens";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../api/Firebase/Firebase";
import "./PlanningView.less";

const { Option } = Select;

const PlanningView = ({
  isLoading,
  isWorking,
  isError,
  errorCode,
  organizer,
  filter,
  timezone,
  floors,
  equipments,
  services,
  serviceCategories,
  availabilities,
  onChangeFilter,
  onClose,
  onSelect,
}) => {
  const { t } = useTranslation();
  const { colors } = useDesignTokens();
  const [searchRoom, setSearch] = useState("");
  const [slots, setSlots] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  global.logger.debug("Filter", filter);

  const tableRef = useRef();

  useEffect(() => {
    setSlots([{ startDate: filter.start, endDate: filter.end }]);
  }, [filter.end, filter.start]);

  useEffect(() => {
    if (tableRef.current && !isWorking) {
      const el = tableRef.current.querySelector(".hourSelected");
      el?.scrollIntoView({ block: "end", inline: "center" });
    }
  }, [isWorking]);

  const handleSearch = (evt) => {
    setSearch(evt.target.value);
  };

  const searchResult = (availabilities || [])?.filter((value) =>
    searchRoom
      ? new RegExp(
          searchRoom.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          "i"
        ).test(value?.label.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
      : true
  );

  const slotToPoucentageBlock = (slot) => {
    const day = moment(filter?.start).startOf("day");
    const start = moment(slot?.startDate);
    const end = moment(slot?.endDate);
    return {
      offset: (start.diff(day, "h", true) / 24) * 100,
      size: (end.diff(start, "h", true) / 24) * 100,
    };
  };

  const columns = [
    {
      title: t("space"),
      dataIndex: "salle",
      width: 150,
      fixed: "left",
      onCell: (text, record) => ({
        className: "background-cellLeft",
      }),
    },
    {
      title: t("seats"),
      dataIndex: "places",
      width: 60,
      fixed: "left",
      sorter: (a, b) => a.places - b.places,
      onCell: (text, record) => ({
        className: "background-cellLeft",
      }),
    },
    {
      title: t("floor"),
      dataIndex: "etage",
      width: 60,
      fixed: "left",
      sorter: (a, b) => a.etage - b.etage,
      onCell: (text, record) => ({
        className: "background-cellLeft",
      }),
    },
  ];
  const data = [];

  const start = moment("00:00:00", "HH:mm:ss");
  const end = moment("23:59:59", "HH:mm:ss");

  columns.push({
    title: start.format("HH:mm"),
    width: 50,
    dataIndex: "hours",
    onCell: (_, index) => ({
      colSpan: 24,
      className: "cellHour",
    }),
  });
  while (start.isBefore(end)) {
    columns.push({
      title: start.add(60, "m").format("HH:mm"),
      width: 50,
      dataIndex: "hours",
      onCell: (_, index) => ({
        colSpan: 0,
        className: "cellHour",
      }),
    });
  }

  searchResult.map((room, index) => {
    data.push({
      key: index,
      salle: (
        <p
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            margin: "0",
          }}
        >
          {room?.label}
        </p>
      ),
      places: room?.seats,
      etage: (
        <p
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            margin: "0",
          }}
        >
          {room?.floor}
        </p>
      ),
      id: room?.id,
      hours: (
        <div style={{ position: "relative", height: "30px" }}>
          {slots.map((slot) => {
            const pourcentageBlock = slotToPoucentageBlock(slot);
            return (
              <div
                style={{
                  width: `${pourcentageBlock.size}%`,
                  left: `${pourcentageBlock.offset}%`,
                }}
                className="hourSelected"
                id="test"
              ></div>
            );
          })}
          {room?.slotsInfos.map((datas) => {
            const pourcentageBlock = slotToPoucentageBlock(datas);
            return (
              <div
                style={{
                  width: `${pourcentageBlock.size}%`,
                  left: `${pourcentageBlock.offset}%`,
                }}
                className="slot"
              ></div>
            );
          })}
        </div>
      ),
    });
  });

  return (
    <div style={{ height: "100%" }}>
      <Spin spinning={isWorking}>
        <Typography.Title level={5}>{t("findSpace")}</Typography.Title>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <div style={{ display: "flex" }}>
            <p
              style={{
                margin: "0 8px 0 0",
                display: "flex",
                alignItems: "center",
              }}
            >
              {t("the")}
              <DatePicker
                value={filter?.start}
                format="DD MMMM YYYY"
                style={{ margin: "0 0px 0 8px", width: "180px" }}
                onChange={(start) => onChangeFilter({ start })}
              />
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              {t("of")}
              <TimePicker
                showNow={false}
                minuteStep={15}
                style={{ margin: "0 10px" }}
                disabledHours={() =>
                  moment(filter.start).format("DD MM YYYY") ===
                    moment().format("DD MM YYYY") && range(0, moment().hour())
                }
                disabledMinutes={(selectedHour) =>
                  moment().hour() === selectedHour
                    ? range(0, moment().minutes())
                    : []
                }
                format="HH:mm"
                value={filter.start}
                onChange={(start) => {
                  onChangeFilter({
                    start: start,
                    end: moment(start).add(30, "minutes"),
                  });
                  setSlots([
                    {
                      startDate: moment(start),
                      endDate: moment(start).add(30, "minutes"),
                    },
                  ]);
                }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              {t("at")}
              <TimePicker
                showNow={false}
                minuteStep={15}
                style={{ margin: "0 10px" }}
                disabledHours={() => range(0, moment(filter?.start).hour())}
                disabledMinutes={(selectedHour) =>
                  moment(filter?.start).hour() === selectedHour
                    ? range(0, moment(filter?.start).minutes())
                    : []
                }
                format="HH:mm"
                value={filter.end}
                onChange={(end) => {
                  if (moment(end) < moment(filter?.start)) {
                    onChangeFilter({ end: moment(filter?.end) });

                    setSlots([
                      {
                        startDate: moment(filter?.start),
                        endDate: moment(filter?.end),
                      },
                    ]);
                  } else {
                    onChangeFilter({ end });
                    setSlots([
                      {
                        startDate: moment(filter?.start),
                        endDate: moment(end),
                      },
                    ]);
                  }
                }}
              />
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <Input
              style={{ width: "260px" }}
              placeholder={t("findSpaceByName")}
              prefix={<Glyph name="search" />}
              size="small"
              onChange={handleSearch}
              //value={searchRoom}
            />
          </div>
        </div>
        <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
          <Col xs={12} xl={6} xxl={6}>
            <Card>
              <Row gutter={[20, 0]}>
                <Col>
                  <Typography.Text strong>
                    <Glyph
                      name="group"
                      style={{
                        marginRight: "8px",
                        color: colors.secondary_base,
                        fontWeight: "normal",
                      }}
                    />
                    {t("minPlaces")}
                  </Typography.Text>
                </Col>
                <Col style={{ paddingLeft: "0px" }}>
                  <InputNumber
                    size={"medium"}
                    min={1}
                    onChange={(v) => {
                      onChangeFilter({ attendeesCount: v });
                      logEvent(
                        analytics,
                        "addon_search_advanced_filter_capacity"
                      );
                    }}
                    max={100}
                    value={filter.attendeesCount}
                    style={{ width: "100%", marginTop: -8, marginBottom: -8 }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={12} xl={5} xxl={5}>
            <Card>
              <Row gutter={[20, 0]}>
                <Col>
                  <Typography.Text strong>
                    <Glyph
                      name="filter_none"
                      style={{
                        color: colors.secondary_base,
                        marginRight: "8px",
                        fontWeight: "normal",
                      }}
                    />
                    {t("floor")}
                  </Typography.Text>
                </Col>
                <Col flex={"auto"}>
                  <Select
                    value={filter.floor}
                    size={"medium"}
                    style={{ width: "100%", marginTop: -8, marginBottom: -8 }}
                    onChange={(s) => {
                      onChangeFilter({ floor: s });
                      logEvent(analytics, "addon_search_advanced_filter_floor");
                    }}
                  >
                    {floors.map((f) => (
                      <Option value={f.order} key={f.order}>
                        {f.label}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={12} xl={7} xxl={7}>
            <Card>
              <Row gutter={[20, 0]}>
                <Col>
                  <Typography.Text strong>
                    <Glyph
                      name="devices"
                      style={{
                        color: colors.secondary_base,
                        marginRight: "8px",
                        fontWeight: "normal",
                      }}
                    />
                    {t("equipments")}
                  </Typography.Text>
                </Col>
                <Col flex={"auto"}>
                  <Select
                    mode="multiple"
                    size={"medium"}
                    value={filter.equipments}
                    style={{ width: "100%", marginTop: -8, marginBottom: -8 }}
                    onChange={(s) => {
                      onChangeFilter({ equipments: s });
                      logEvent(
                        analytics,
                        "addon_search_advanced_filter_devices"
                      );
                    }}
                    placeholder={t("none")}
                    maxTagCount="responsive"
                  >
                    {equipments.map((eq) => (
                      <Option value={eq.id} key={eq.id}>
                        {eq.label}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={12} xl={6} xxl={6}>
            <Card>
              <Row gutter={[20, 0]}>
                <Col>
                  <Typography.Text strong>
                    <Glyph
                      name="room_service"
                      style={{
                        color: colors.secondary_base,
                        marginRight: "8px",
                        fontWeight: "normal",
                      }}
                    />
                    {t("prestations")}
                  </Typography.Text>
                </Col>
                <Col flex={"auto"}>
                  <Select
                    mode="multiple"
                    size={"medium"}
                    style={{ width: "100%", marginTop: -8, marginBottom: -8 }}
                    value={filter.serviceCategories}
                    onChange={(s) => {
                      onChangeFilter({ serviceCategories: s });
                      logEvent(
                        analytics,
                        "addon_search_advanced_filter_services"
                      );
                    }}
                    placeholder={t("none")}
                    maxTagCount="responsive"
                  >
                    {serviceCategories.map((svc) => (
                      <Option value={svc} key={svc}>
                        {svc}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <div ref={tableRef}>
          <Table
            size="small"
            showHeader={true}
            style={{ marginTop: "16px" }}
            rowSelection={{
              type: "radio",
              onChange: (_, value) => {
                setSelectedRows(value);
                setIsDisabled(false);
              },
            }}
            dataSource={data}
            columns={columns}
            scroll={{
              x: 2200,
              y: 500,
            }}
            pagination={{
              pageSize: 15,
              showSizeChanger: false,
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            type="default"
            onClick={() => onClose()}
            style={{ marginRight: "16px", fontWeight: "bold", width: "170px" }}
          >
            {t("close")}
          </Button>
          <Button
            type="primary"
            style={{ fontWeight: "bold" }}
            disabled={isDisabled}
            onClick={() => {
              onSelect(
                selectedRows[0]?.id,
                filter.start.toISOString(),
                filter.end.toISOString()
              );
              logEvent(analytics, "addon_select_space_from_advanced_search");
            }}
          >
            {t("selectSpace")}
          </Button>
        </div>
      </Spin>
    </div>
  );
};
export default PlanningView;
