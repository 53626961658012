import React, { useEffect, useRef, useState } from "react";
import qs from "qs";
import moment from "moment";
import PlanningView from "views/PlanningView";
import useSwizi from "managers/SwiziManager";

const PlanningContainer = ({
  organizer,
  startDate,
  endDate,
  token,
  appId,
  locale = "fr",
  initialFilter,
}) => {
  const { logger } = global;

  const {
    swiziIsLoading,
    swiziIsWorking,
    swiziIsError,
    swiziErrorMessage,
    swiziIsReady,
    slots,
    floors,
    filter,
    equipments,
    services,
    serviceCategories,
    ticketTypes,
    availabilities,
    isAgent,
    timezone,
    swiziManager,
  } = useSwizi(undefined, locale);

  useEffect(() => {
    swiziManager.getConfiguration(appId, token);
  }, []);

  useEffect(() => {
    if (swiziIsReady) {
      let firstFilter = Object.assign(initialFilter, {
        start: moment(startDate),
        end: moment(endDate),
      });

      logger.debug("initialize filter", firstFilter);
      swiziManager.updateFilter(firstFilter);
    }
  }, [swiziIsReady]);

  const handleClose = (room, start, end) => {
    logger.debug("Planning selection", { room, start, end });
    if (room)
      window.Office.context.ui.messageParent(
        `OpenRoom#${room}&${start}&${end}`
      );
    else window.Office.context.ui.messageParent(`Close#`);
  };

  return (
    <PlanningView
      isLoading={swiziIsLoading}
      isWorking={swiziIsWorking}
      isError={swiziIsError}
      errorMessage={swiziErrorMessage}
      organizer={organizer}
      filter={filter}
      timezone={timezone}
      floors={floors}
      equipments={equipments}
      services={services}
      serviceCategories={serviceCategories}
      availabilities={availabilities}
      onChangeFilter={(criteria) => swiziManager.updateFilter(criteria)}
      onClose={() => handleClose()}
      onSelect={handleClose}
    />
  );
};

export default PlanningContainer;
