import * as R from "ramda";
import moment from "moment";

export const checkDelayRule = (desiredDate, restriction, now = moment()) => {
  let daysBefore = 0;
  let hoursBefore = 0;
  let hasDaysBefore = restriction.match(/(^[0-9]{1,3}days)+/g);
  let hasHoursBefore = restriction.match(/([0-9]{1,2}h)+/g);
  if (hasDaysBefore) daysBefore = parseInt(hasDaysBefore[0]);
  if (hasHoursBefore) hoursBefore = parseInt(hasHoursBefore[0]);

  let max;
  if (daysBefore) {
    let minutesBefore = 0;

    if (!hasHoursBefore) {
      hoursBefore = moment(desiredDate).hours();
      minutesBefore = moment(desiredDate).minutes();
    }
    max = moment(desiredDate);
    max
      .set("hour", hoursBefore)
      .set("minute", minutesBefore)
      .set("second", 0)
      .set("millisecond", 0)
      .subtract(daysBefore, "days");
    return now.isBefore(max);
  } else {
    max = now;
    max.add(hoursBefore, "hour");
    return moment(desiredDate).isAfter(max);
  }
};

const requestsHelper = (ticketTypes, locale) => {
  const services = [];

  const translate = (content) => {
    if (!content) return "";
    if (content[locale]) return content[locale];
    if (content["en"]) return content["en"];

    if (typeof content === "string") return content;
    return content[0];
  };

  const mappingTTCategory = {};
  const mappingPathCategory = {};

  const parse = (category, ticketTypeId, path = []) => {
    if (category.form) {
      services.push(Object.assign(category, { ticketTypeId }));
      mappingTTCategory[category.id] = ticketTypeId;
      mappingPathCategory[category.id] = path;
    } else {
      category.categories.forEach((cat) => {
        let newPath = JSON.parse(JSON.stringify(path));
        newPath.push({ id: category.id, label: translate(category.name) });
        parse(cat, ticketTypeId, newPath);
      });
    }
  };

  ticketTypes.forEach((tt) => parse(tt, tt.id));

  const getServicesFromProducts = (products) => {
    let result = [];

    if (products.length === 0) return result;
    let selection = [];

    products.forEach((prd) => {
      selection = selection.concat(
        selection,
        services
          .filter(
            (svc) =>
              (svc.form.elements || []).findIndex((elm) => elm.key === prd) > -1
          )
          .map((svc) => svc.id)
      );
    });
    result = R.uniq(selection, result);

    return result;
  };

  const getServicesTreeFromProducts = (products) => {
    const selectedServices = getServicesFromProducts(products);
    let tree = [];

    services
      .filter((svc) => selectedServices.indexOf(svc.id) > -1)
      .forEach((svc) => {
        let item = tree;
        mappingPathCategory[svc.id].forEach((step, idx) => {
          //first level is ignored
          if (idx !== 0 || mappingPathCategory[svc.id].length === 1) {
            let nextItem = item.find((i) => i.id === step.id);
            if (!nextItem) {
              nextItem = { id: step.id, label: step.label, categories: [] };
              item.push(nextItem);
            }

            if (idx === mappingPathCategory[svc.id].length - 1) {
              nextItem.categories.push(
                Object.assign(svc, {
                  ticketTypeId: mappingTTCategory[svc.id],
                  form: Object.assign(svc.form, {
                    elements: svc.form.elements
                      .filter(
                        (el) =>
                          (!el.isProduct ||
                            (el.isProduct && products.indexOf(el.key))) > -1
                      )
                      .map((el) =>
                        Object.assign(el, {
                          description: translate(el.description),
                          name: translate(el.name),
                        })
                      ),
                    description: translate(svc.form.description),
                  }),
                  categories: undefined,
                  name: translate(svc.name),
                  description: translate(svc.description),
                })
              );
            }

            item = nextItem.categories;
          }
        });
      });

    return tree;
  };

  return {
    getServices: () =>
      services.map((svc) => ({
        id: svc.id,
        label: translate(svc.name),
      })),
    getServicesFromProducts,
    getServicesTreeFromProducts,
  };
};

export default requestsHelper;
