import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import SearchView from "views/SearchView";
import RoomDetailsView from "views/RoomDetailsView";
import ErrorView from "views/ErrorView";
import useOffice from "managers/OfficeManager";
import { useTranslation } from "react-i18next";
import { Image, Spin, Typography } from "antd";

import Logo from "@tokens/Assets/00 - Brand/01 Logo/01 Standard.png";
import "./PanelContainer.less";

const PanelContainer = () => {
  const { t } = useTranslation();

  const {
    isReady,
    isLoading,
    isWorking,
    isError,
    errorMessage,
    start,
    end,
    attendees,
    location,
    organizer,
    timezone,
    extras,
    floors,
    filter,
    equipments,
    services,
    serviceCategories,
    availabilities,
    roomRequests,
    isAgent,
    officeManager,
    swiziManager,
    attendeesCount,
    isRecurrentMeeting,
    currentSite,
    showVisitors,
    sites,
  } = useOffice();

  useEffect(() => {
    officeManager.initialize();
  }, []);

  useEffect(() => {
    if (isReady) {
      swiziManager.updateFilter({
        start: moment(start),
        end: moment(end),
      });
    }
  }, [isReady]);

  const handleShowPlanning = async () => {
    await officeManager.showPlanning();
  };

  const handleSelect = (room) => {
    officeManager.addLocation(room);
  };

  const handleClearLocation = () => {
    officeManager.clearLocation();
  };

  const handleChangeSite = (siteId) => {
    swiziManager.updateSite(siteId);
  };

  if (isError) return <ErrorView error={errorMessage} />;
  else if (!isReady)
    return (
      <div className="globalLoader">
        <div>
          <Image preview={false} src={Logo} height={94} />
          <br />
          <Spin></Spin>
          <br />
          <Typography.Text strong>{t("notReady")}</Typography.Text>
        </div>
      </div>
    );
  else if (location && extras)
    return (
      <RoomDetailsView
        location={location}
        start={start}
        end={end}
        attendees={attendees}
        attendeesCount={attendeesCount}
        invitedGuests={extras.invitedGuests}
        underConstruction={extras.isUnderConstruction}
        requests={extras.requests}
        isRoomAvailable={swiziManager.isRoomAvailable(location.email)}
        roomRequests={roomRequests}
        services={services}
        serviceCategories={serviceCategories}
        isAgent={isAgent}
        isRecurrentMeeting={isRecurrentMeeting}
        showVisitors={showVisitors}
        onCreateVisitor={(data) => officeManager.createAGuest(data)}
        onUpdateExtras={(data) => officeManager.updateExtra(data)}
        onClearLocation={handleClearLocation}
      />
    );
  else
    return (
      <SearchView
        location={location}
        attendeesCount={attendeesCount}
        isWorking={isWorking}
        organizer={organizer}
        start={start}
        end={end}
        floors={floors}
        equipments={equipments}
        services={services}
        serviceCategories={serviceCategories}
        filter={filter}
        availabilities={availabilities}
        isRoomAvailable={swiziManager.isRoomAvailable(location?.email)}
        timezone={timezone}
        currentSite={currentSite}
        sites={sites}
        onChangeFilter={(criteria) => swiziManager.updateFilter(criteria)}
        onSelect={(room) => handleSelect(room)}
        onShowPlanning={handleShowPlanning}
        onChangeSite={handleChangeSite}
      />
    );
};

export default PanelContainer;
