import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import packageJson from "../../../package.json";

import classNames from "classnames/bind";
import styles from "./ErrorView.less";

const cx = classNames.bind(styles);

const ErrorView = ({ error }) => {
  const { t } = useTranslation();

  return (
    <div>
      Une erreur s'est produite :<br />
      <br />
      {error}
      <br /> <br />
      Version {packageJson.version}
    </div>
  );
};
export default ErrorView;
