import Layout from "layout/Layout";
import packageJson from "../package.json";
import { useEffect, useState } from "react";
import enUS from "antd/lib/locale/en_US";
import frFR from "antd/lib/locale/fr_FR";
import nlBE from "antd/lib/locale/nl_BE";

import moment from "moment";
import "moment/locale/fr";
import "moment/locale/nl";

import "./App.css";
import { useTranslation } from "react-i18next";
import { ConfigProvider } from "antd";

const antLocales = {
  fr: frFR,
  en: enUS,
  nl: nlBE,
};
function App() {
  const { i18n } = useTranslation();
  const lng = i18n.language.split("-")[0];

  const { logger } = global;
  const [ready, setReady] = useState(false);
  const [cookieError, setCookieError] = useState(false);
  const [noOfficeError, setNoOfficeError] = useState(false);

  moment.locale(lng, {
    week: {
      dow: 1, // Monday is the first day of the week.
      doy: 4, // Used to determine first week of the year.
    },
  });

  const start = async () => {
    if (!window?.Office) return setNoOfficeError(true);

    logger.debug("Office is detected");

    let cookieAllowed = true;

    try {
      window.localStorage.getItem("test local storage access");
    } catch (error) {
      cookieAllowed = false;
    }

    if (!cookieAllowed) return setCookieError(true);
    logger.debug("Cookies allowed");

    setReady(true);
  };

  useEffect(() => {
    start();
  }, []);

  if (noOfficeError)
    return (
      <div>
        <p>
          Ce composant doit être utilisé dans l'environnement Outlook ... (v
          {packageJson.version})
        </p>
        <p>
          {process.env.REACT_APP_PLANNER_BACK_URL || `Planner non configuré`}
        </p>
      </div>
    );

  if (cookieError)
    return (
      <div style={{ margin: "10px 10px 10px" }}>
        <div>
          Les cookies sont actuellement désactivés sur votre navigateur.{" "}
          <br></br>Cet add-on Outlook nécessite cette fonctionnalité pour offrir
          une meilleure expèrience utilisateur.
          <br></br> Si vous ne souhaitez pas activer les cookies sur tous les
          sites, vous pouvez limiter l'accès à l'adresse suivante :
          https://addin-outlook.swizi.io
        </div>
        <br></br>
        <div>
          Cookies are currently disabled on your browser. <br></br>This Outlook
          add-on requires this feature to provide a better user experience.
          <br></br>
          If you do not wish to enable cookies on all websites, you can limit
          access to the following website: https://addin-outlook.swizi.io
        </div>
      </div>
    );

  if (ready)
    return (
      <ConfigProvider componentSize="large" locale={antLocales[lng]}>
        <div className="App">
          <Layout />
        </div>
      </ConfigProvider>
    );
  else return <div className="App">Outlook initialization</div>;
}

export default App;
