import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Typography,
  Image,
  Tag,
  Divider,
  Button,
  Space,
  Form,
  Checkbox,
  Tooltip,
  Drawer,
  Switch,
  message,
  Spin,
} from "antd";

import "./RoomDetailsView.less";
import useDesignTokens from "hook/useDesignTokens";
import Glyph from "views/Common/Glyph/Glyph";
import Tile from "views/Common/Tile/Tile";
import AddGuest from "./AddGuest";
import AddServices from "./AddServices";
import { isEmpty, remove, update } from "ramda";
import { useTranslation } from "react-i18next";
import UpdateServices from "./UpdateServices";
import { ReactComponent as Finish } from "../../Images/finish.svg";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../api/Firebase/Firebase";
const STEPS = {
  SPACES: "SPACES",
  GUESTS: "GUESTS",
  SERVICES: "SERVICES",
  RECAP: "RECAP",
  FINISH: "FINISH",
};

const RoomDetailsView = ({
  location,
  start,
  end,
  isAgent,
  attendees,
  invitedGuests,
  underConstruction,
  requests,
  isRoomAvailable,
  roomRequests,
  isRecurrentMeeting,
  onCreateVisitor,
  services,
  showVisitors,
  onUpdateExtras,
  onClearLocation,
}) => {
  const { logger } = global;
  const { t } = useTranslation();
  global.logger.debug("showVisitors", showVisitors);
  console.log("showVisitors", showVisitors);

  const { colors } = useDesignTokens();
  const [form] = Form.useForm();

  const [step, setStep] = useState(STEPS.SPACES);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [guestInfos, setGuestInfos] = useState();
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [isLoading, setIsloading] = useState(true);
  const [prestations, setPrestations] = useState(
    requests?.map((re) => {
      if (re.id && re.name) return re;
      const roomRequest = roomRequests.find((re2) =>
        re2.categories.some((c) => c.id === re.categoryId)
      );
      if (!roomRequest) return re;
      const category = roomRequest.categories.find(
        (c) => c.id === re.categoryId
      );
      return { ...re, id: roomRequest.id, name: category.name };
    })
  );
  const [infos, setInfos] = useState();
  const [index, setIndex] = useState();
  const [isunderConstruction, setIsunderConstruction] =
    useState(underConstruction);

  useEffect(() => {
    logger.debug("Open RoomDetailView", {
      requests,
      roomRequests,
      prestations,
    });
  }, [prestations, logger, requests, roomRequests]);

  useEffect(() => {
    logger.debug("RoomDetailView requests/invitedGuests updated", {
      requests,
      invitedGuests,
    });
    invitedGuests?.forEach((guest) =>
      form.setFieldValue(["guests", guest.email], true)
    );
  }, [requests, invitedGuests, logger, form]);

  const handleSubmit = async (values) => {
    const guests = values.guests
      ? Object.entries(values.guests).reduce(
          (acc, [email, checked]) => (checked ? [...acc, email] : acc),
          []
        )
      : [];
    onUpdateExtras({
      underConstruction: values.construction,
      invitedGuests: guests,
      requests: prestations,
    })
      .then(() => setIsloading(false))
      .catch((error) => {
        console.error(error);
        setIsloading(false);
        message.error(t("ErrorSave"));
      });
  };

  useEffect(() => {
    if (attendees?.length !== 0) {
      logEvent(analytics, "addon_add_visitor");
    }
  }, [attendees?.length]);

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      initialValues={{ construction: underConstruction }}
      onValuesChange={(_, values) => {
        setIsunderConstruction(values.construction);
      }}
    >
      <div style={{ display: step === STEPS.SPACES ? "block" : "none" }}>
        <Row
          style={{
            display: "flex",
            flexFlow: "column",
            justifyContent: "space-between",
            height: "90vh",
          }}
        >
          <div>
            <Typography.Title
              strong
              level={4}
              style={{ marginTop: "16px", marginBottom: "0" }}
            >{`${location.label}`}</Typography.Title>
            {/* <Typography.Paragraph strong>Type de salle</Typography.Paragraph> */}
            <Typography.Paragraph>
              {t("floor")} {location.floor.label}
            </Typography.Paragraph>

            <Col span={24} style={{ marginTop: "16px", marginBottom: "16px" }}>
              <Row gutter={[8, 8]} wrap={false} style={{ overflow: "auto" }}>
                {location.photos.map((loc, key) => (
                  <>
                    <Col key={key} span="12" style={{ marginBottom: "8px" }}>
                      <div className="pictures">
                        <Image preview={false} key={key} src={loc} />
                      </div>
                    </Col>
                  </>
                ))}
              </Row>
            </Col>
            <div style={{ marginBottom: "40px" }}>
              <div
                style={{
                  display: "flex",
                  flexFlow: "column",
                  marginBottom: "8px",
                }}
              >
                <Typography.Text strong>{t("equipments")}</Typography.Text>
                {isEmpty(location.equipments) ? (
                  <Typography.Text>{t("noEquipment")}</Typography.Text>
                ) : (
                  <div style={{ marginTop: "8px" }}>
                    {location.equipments.map((eq, key) => (
                      <Tag
                        key={key}
                        color={colors.primary_light}
                        style={{ marginBottom: "8px" }}
                      >
                        <Glyph name={eq.icon} style={{ marginRight: "8px" }} />
                        <Typography.Text strong style={{ color: "white" }}>
                          {eq.label}
                        </Typography.Text>
                      </Tag>
                    ))}
                    {location?.equipments.length > 5 && (
                      <Typography.Text strong>
                        {Math.abs(location?.services.length - 5)} de plus
                      </Typography.Text>
                    )}
                    <Tag
                      color={colors.primary_light}
                      style={{ marginBottom: "8px" }}
                    >
                      <Typography.Text strong style={{ color: "white" }}>
                        <Glyph
                          name="group"
                          style={{
                            verticalAlign: "-4px",
                            marginRight: "8px",
                          }}
                        />
                        {location.seats}
                      </Typography.Text>
                    </Tag>
                  </div>
                )}
              </div>
              <div style={{ display: "flex", flexFlow: "column" }}>
                <Typography.Text strong>{t("prestations")}</Typography.Text>
                {isEmpty(location?.serviceCategories) ? (
                  <Typography.Text>{t("noPrestaSpace")}</Typography.Text>
                ) : (
                  <div style={{ marginTop: "8px" }}>
                    {location?.serviceCategories
                      .slice(0, 5)
                      .map((service, key) => (
                        <Tag
                          key={key}
                          color={colors.primary_light}
                          style={{ marginBottom: "8px" }}
                        >
                          <Typography.Text strong style={{ color: "white" }}>
                            {service}
                          </Typography.Text>
                        </Tag>
                      ))}
                    {location?.serviceCategories.length > 5 && (
                      <Typography.Text strong>
                        {Math.abs(location?.services.length - 5)} {t("more")}
                      </Typography.Text>
                    )}
                  </div>
                )}
              </div>
            </div>
            {isAgent && (
              <>
                <Typography.Paragraph strong>
                  <Glyph
                    name="engineering"
                    style={{ marginRight: "8px", fontWeight: "normal" }}
                  />
                  {t("underConstruction")}
                </Typography.Paragraph>
                <Typography.Paragraph type="secondary">
                  {t("statutUndeconstruction")}
                </Typography.Paragraph>
                <Form.Item valuePropName="checked" name={["construction"]}>
                  <Switch />
                </Form.Item>
              </>
            )}
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="default"
              style={{ marginRight: "8px", width: "100px" }}
              onClick={() => onClearLocation()}
            >
              {t("back")}
            </Button>
            <Button
              style={{ width: "100px" }}
              type="primary"
              onClick={() =>
                isunderConstruction
                  ? setStep(STEPS.RECAP)
                  : !showVisitors && isEmpty(roomRequests)
                  ? setStep(STEPS.RECAP)
                  : !showVisitors
                  ? setStep(STEPS.SERVICES)
                  : setStep(STEPS.GUESTS)
              }
            >
              {t("next")}
            </Button>
          </div>
        </Row>
      </div>
      <div style={{ display: step === STEPS.GUESTS ? "block" : "none" }}>
        <Row
          style={{
            display: "flex",
            flexFlow: "column",
            justifyContent: "space-between",
            height: "90vh",
          }}
        >
          <div>
            <Typography.Title level={5}>{t("guests")}</Typography.Title>
            <Tile style={{ padding: "8px", height: "auto", cursor: "default" }}>
              <Glyph name="person_add" style={{ marginRight: "16px" }} />
              <div style={{ display: "flex", flexFlow: "column" }}>
                <Typography.Text strong>{t("addGuests")}</Typography.Text>
                <Typography.Text type="secondary">
                  {t("addGuestsLeft")}
                </Typography.Text>
              </div>
            </Tile>
            <Divider style={{ margin: "8px" }}></Divider>
            {attendees?.length !== 0 && (
              <Typography.Text
                type="secondary"
                strong
                style={{ fontSize: "12px", marginBottom: "16px" }}
              >
                {t("selectedGuest")}
              </Typography.Text>
            )}

            {attendees?.length !== 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "8px",
                }}
              >
                <Typography.Text
                  style={{ cursor: "pointer", color: colors.secondary_base }}
                  onClick={() => {
                    let checkall = false;
                    for (const attendee of attendees) {
                      if (
                        !form.getFieldValue(["guests", attendee.email]) &&
                        attendee?.existsAsVisitor
                      ) {
                        checkall = true;
                      } else if (!attendee?.existsAsVisitor) {
                        setIsModalOpen(true);
                        setGuestInfos(attendee);
                      }
                    }

                    return form.setFieldsValue({
                      guests: {
                        ...attendees
                          .filter((a) => a.existsAsVisitor === true)
                          .reduce(
                            (acc, curr) => ({
                              ...acc,
                              [curr.email]: checkall,
                            }),
                            {}
                          ),
                      },
                    });
                  }}
                >
                  {t("selectAll")}
                </Typography.Text>
              </div>
            )}

            <Space style={{ width: "100%" }} direction="vertical" size="small">
              {attendees?.map((attendee, index) => (
                <Tile
                  key={index}
                  style={{
                    cursor: !attendee?.existsAsVisitor ? "pointer" : "default",
                    height: "auto",
                    padding: "12px",
                  }}
                >
                  {!attendee?.existsAsVisitor ? (
                    <Tooltip
                      placement="top"
                      title={<div>{t("guestsNoSave")}</div>}
                    >
                      <Typography.Text strong style={{ maxWidth: "250px" }}>
                        <Glyph
                          name="warning"
                          style={{
                            color: colors.error_light,
                            marginRight: "8px",
                            fontWeight: "normal",
                          }}
                        />
                        {attendee?.email}
                      </Typography.Text>
                    </Tooltip>
                  ) : (
                    <Typography.Text strong>
                      {attendee?.displayName}
                    </Typography.Text>
                  )}
                  <Form.Item
                    noStyle
                    name={["guests", attendee?.email]}
                    valuePropName="checked"
                    initialValue={invitedGuests?.find(
                      (i) => i?.id === attendee?.id
                    )}
                    normalize={(value) => {
                      if (!attendee?.existsAsVisitor) {
                        setIsModalOpen(true);
                        setGuestInfos(attendee);
                        return false;
                      }
                      return value;
                    }}
                  >
                    <Checkbox></Checkbox>
                  </Form.Item>
                </Tile>
              ))}
            </Space>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "16px",
            }}
          >
            <Button
              type="default"
              style={{ width: "100px" }}
              onClick={() => setStep(STEPS.SPACES)}
            >
              {t("back")}
            </Button>
            <Button
              style={{ marginLeft: "8px", width: "100px" }}
              type="primary"
              onClick={() =>
                setStep(isEmpty(roomRequests) ? STEPS.RECAP : STEPS.SERVICES)
              }
            >
              {t("next")}
            </Button>
          </div>
        </Row>
      </div>
      <div style={{ display: step === STEPS.SERVICES ? "block" : "none" }}>
        <Row
          style={{
            display: "flex",
            flexFlow: "column",
            justifyContent: "space-between",
            height: "90vh",
          }}
        >
          <div>
            <Typography.Title level={5}>{t("prestations")}</Typography.Title>
            {prestations?.length === 0 ? (
              <Typography.Text
                type="secondary"
                strong
                style={{ marginBottom: "8px" }}
              >
                {t("noPrestation")}
              </Typography.Text>
            ) : (
              <Space
                style={{ width: "100%" }}
                direction="vertical"
                size="small"
              >
                {prestations?.map((prestation, index) => (
                  <Tile
                    key={index}
                    onClick={() => {
                      setOpenUpdate(true);
                      setInfos(prestation);
                      setIndex(index);
                    }}
                  >
                    <Typography.Text strong>{prestation?.name}</Typography.Text>
                    <div
                      style={{
                        display: "flex",
                        flexFlow: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography.Text strong>
                        {prestation?.formProps?.no_Attendees}
                      </Typography.Text>
                      <div style={{ marginLeft: "8px" }}>
                        <Glyph name="search" />
                      </div>
                    </div>
                  </Tile>
                ))}
              </Space>
            )}

            <Button
              type="primary"
              icon={<Glyph name="add" />}
              onClick={() => setOpen(true)}
              style={{ width: "100%", marginTop: "8px", marginBottom: "8px" }}
              disabled={isRecurrentMeeting}
            >
              {t("addPrestation")}
            </Button>
            {isRecurrentMeeting && (
              <Typography.Text strong>
                {t("prestationRecurring")}
              </Typography.Text>
            )}
            <Drawer
              bodyStyle={{ paddingTop: "8px" }}
              height="100%"
              drawerStyle={{ padding: "8px 0" }}
              closeIcon={<Glyph name="expand_more" />}
              title={
                <Typography.Title level={5} style={{ margin: "0" }}>
                  {t("addPrestation")}
                </Typography.Title>
              }
              placement="bottom"
              closable={true}
              onClose={() => setOpen(false)}
              open={open}
            >
              <AddServices
                onSubmit={(
                  values,
                  service,
                  info,
                  total,
                  noAttendees,
                  idParent
                ) => {
                  setOpen(false);
                  logEvent(analytics, "addon_add_service");
                  setPrestations([
                    ...prestations,
                    {
                      id: idParent,
                      name: info?.name,
                      categoryId: info?.id,
                      type: info?.ticketTypeId,
                      formProps: {
                        no_Attendees: noAttendees,
                        total: total,
                        values: service,
                      },
                    },
                  ]);
                }}
                services={roomRequests}
                open={open}
                start={start}
                infos={infos}
                setOpen={setOpen}
              />
            </Drawer>
            <Drawer
              destroyOnClose={true}
              bodyStyle={{ paddingTop: "8px" }}
              height="100%"
              title={
                <Typography.Title level={5} style={{ margin: "0" }}>
                  {t("updatePrestation")}
                </Typography.Title>
              }
              placement="bottom"
              closable={true}
              closeIcon={<Glyph name="expand_more" />}
              onClose={() => setOpenUpdate(false)}
              open={openUpdate}
            >
              <UpdateServices
                onSubmit={(
                  values,
                  service,
                  info,
                  total,
                  noAttendees,
                  idParent,
                  cancel
                ) => {
                  setOpenUpdate(false);
                  cancel
                    ? logEvent(analytics, "addon_delete_service")
                    : logEvent(analytics, "addon_update_service");
                  cancel
                    ? setPrestations(remove(index, 1, prestations))
                    : setPrestations(
                        update(
                          index,
                          {
                            id: idParent,
                            name: info?.name,
                            categoryId: info?.id,
                            type: info?.ticketTypeId,
                            formProps: {
                              no_Attendees: noAttendees,
                              total: total,
                              values: service,
                            },
                          },
                          prestations
                        )
                      );
                }}
                open={openUpdate}
                infos={infos}
                services={roomRequests}
                start={start}
              />
            </Drawer>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "16px",
            }}
          >
            <Button
              type="default"
              style={{ marginRight: "8px", width: "100px" }}
              onClick={() => {
                !showVisitors ? setStep(STEPS.SPACES) : setStep(STEPS.GUESTS);
              }}
            >
              {t("back")}
            </Button>
            <Button
              type="primary"
              style={{ width: "100px" }}
              onClick={() => setStep(STEPS.RECAP)}
            >
              {t("next")}
            </Button>
          </div>
        </Row>
      </div>
      <div style={{ display: step === STEPS.RECAP ? "block" : "none" }}>
        <Row
          style={{
            display: "flex",
            flexFlow: "column",
            justifyContent: "space-between",
            height: "90vh",
          }}
        >
          <div>
            <Typography.Text strong type="secondary">
              {t("recap")}
            </Typography.Text>
            <Typography.Title level={5}>{t("location")}</Typography.Title>
            <Tile className="location">
              <div
                style={{
                  display: "flex",
                  flexFlow: "row",
                  alignItems: "center",
                }}
              >
                <Glyph
                  name="meeting_room"
                  style={{ color: colors.grey_40, marginRight: "12px" }}
                />
                <div
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    marginRight: "12px",
                  }}
                >
                  <Typography.Text strong>{location.label}</Typography.Text>
                  <Typography.Text
                    type="secondary"
                    style={{ fontSize: "12px" }}
                  >
                    <Glyph name="group" /> {location.seats}
                  </Typography.Text>
                </div>
              </div>
            </Tile>
            {isunderConstruction && (
              <>
                <Typography.Title level={5} style={{ marginBottom: "0px" }}>
                  <Glyph
                    name="engineering"
                    style={{ marginRight: "8px", fontWeight: "normal" }}
                  />
                  {t("underConstruction")}
                </Typography.Title>
                <Typography.Text>{t("spaceUnderConstruction")}</Typography.Text>
              </>
            )}
            {showVisitors && (
              <>
                <Typography.Title level={5} style={{ marginBottom: "0px" }}>
                  {t("guests")}
                </Typography.Title>
                {attendees?.filter((a) => a?.displayName)?.length === 0 ? (
                  <Typography.Text strong style={{ fontSize: "12px" }}>
                    {t("noGuest")}
                  </Typography.Text>
                ) : (
                  <Typography.Text
                    strong
                    type="secondary"
                    style={{ fontSize: "12px" }}
                  >
                    {t("checkGuest")}
                  </Typography.Text>
                )}
                <Space
                  style={{ width: "100%", marginBottom: "16px" }}
                  direction="vertical"
                  size="small"
                >
                  {attendees
                    ?.filter((a) => a?.displayName)
                    ?.map((attendee, index) => (
                      <Tile key={index} style={{ cursor: "default" }}>
                        <Typography.Text strong>
                          {attendee.displayName}
                        </Typography.Text>
                        <Form.Item
                          noStyle
                          name={["guests", attendee?.email]}
                          valuePropName="checked"
                          initialValue={invitedGuests?.find(
                            (i) => i?.id === attendee?.id
                          )}
                          normalize={(value) => {
                            if (!attendee?.existsAsVisitor) {
                              setIsModalOpen(true);
                              setGuestInfos(attendee);
                              return false;
                            }
                            return value;
                          }}
                        >
                          <Checkbox></Checkbox>
                        </Form.Item>
                      </Tile>
                    ))}
                </Space>
              </>
            )}

            <Typography.Title level={5}>{t("prestations")}</Typography.Title>
            {isEmpty(roomRequests) ? (
              <Typography.Text strong style={{ fontSize: "12px" }}>
                {t("noPrestaSpace")}
              </Typography.Text>
            ) : prestations?.length === 0 ? (
              <Typography.Text strong style={{ fontSize: "12px" }}>
                {t("noPrestation")}
              </Typography.Text>
            ) : (
              <Space
                style={{ width: "100%" }}
                direction="vertical"
                size="small"
              >
                {prestations?.map((prestation, index) => (
                  <Tile key={index} style={{ cursor: "default" }}>
                    <Typography.Text strong>{prestation?.name}</Typography.Text>
                    <Typography.Text strong>
                      {prestation?.formProps?.no_Attendees}
                    </Typography.Text>
                  </Tile>
                ))}
              </Space>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "16px",
            }}
          >
            <Button
              type="default"
              style={{ marginRight: "8px", width: "100px" }}
              onClick={() => {
                isunderConstruction
                  ? setStep(STEPS.SPACES)
                  : !showVisitors && isEmpty(roomRequests)
                  ? setStep(STEPS.SPACES)
                  : isEmpty(roomRequests)
                  ? setStep(STEPS.GUESTS)
                  : setStep(STEPS.SERVICES);
              }}
            >
              {t("back")}
            </Button>
            <Button
              type="primary"
              style={{ width: "100px" }}
              onClick={() => {
                logEvent(analytics, "addon_send_booking")
                setStep(STEPS.FINISH);
                form.submit();
              }}
            >
              {t("save")}
            </Button>
          </div>
        </Row>
      </div>
      <div style={{ display: step === STEPS.FINISH ? "block" : "none" }}>
        <Spin spinning={isLoading} tip={t("LoaderSavePresta")}>
          <Row
            style={{
              display: "flex",
              flexFlow: "column",
              justifyContent: "space-between",
              height: "90vh",
            }}
          >
            <div>
              <Finish />
              <div
                style={{
                  display: "flex",
                  flexFlow: "column",
                  textAlign: "center",
                  marginTop: "20px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  strong
                  type="secondary"
                  style={{ width: "300px", marginTop: "20px" }}
                >
                  {t("finishClickLeft")}
                </Typography.Text>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "16px",
                flexFlow: "column",
                alignItems: "center",
              }}
            >
              <Typography.Text
                style={{
                  width: "200px",
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                {t("updateInfos")}
              </Typography.Text>
              <Button
                style={{ marginTop: "16px", width: "100px" }}
                type="default"
                onClick={() => {
                  setStep(STEPS.RECAP);
                  setIsloading(true);
                }}
              >
                {t("update")}
              </Button>
            </div>
          </Row>
        </Spin>
      </div>
      <AddGuest
        visible={isModalOpen}
        infos={guestInfos}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        onSubmit={(values) => {
          logEvent(analytics, "addon_new_contact");
          onCreateVisitor({
            email: values.email,
            firstname: values.firstname,
            lastname: values.lastname,
            company: values.company,
            phone: values.phone,
          });
          setIsModalOpen(false);
        }}
      />
    </Form>
  );
};
export default RoomDetailsView;
