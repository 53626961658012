import React from "react";
import ReactDOM from "react-dom";
import App from "App";
import "./i18n";
import "./index.less";
import "./styles/index.less";
import "./styles/antd.less";
import "./styles/fonts";

global.logger = {
  debug: (message, ...props) => {
    if (process.env.REACT_APP_DEBUG === "true") {
      console.log(`SwiziLog - ${message}`, ...props);
    }
  },
  error: (message, ...props) =>
    console.error(`SwiziLog - ${message}`, ...props),
};

window.Office.onReady(() => {
  ReactDOM.render(<App />, document.getElementById("root"));
});
