import { Button, Form, Modal, Input, Typography } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const AddGuest = ({ visible, onCancel, infos, onSubmit }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const handleSubmit = async (values) => {
    try {
      await onSubmit(values);
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [form, visible]);

  return (
    <Modal
      title={t("InformationGuest")}
      open={visible}
      onCancel={onCancel}
      bodyStyle={{ padding: "10px 24px" }}
      footer={[
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={onCancel} type="default">
            {t("cancel")}
          </Button>
          <Button
            onClick={() => {
              form.submit();
            }}
            type="primary"
          >
            {t("save")}
          </Button>
        </div>,
      ]}
    >
      <Form
        form={form}
        initialValues={{ email: infos?.email }}
        onFinish={handleSubmit}
      >
        <Typography.Text type="secondary">{t("subInfoGuest")}</Typography.Text>
        <Form.Item
          rules={[{ required: true, message: t("emailRequired") }]}
          name={["email"]}
        >
          <Input placeholder={t("email")} />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: t("firstNameRequired") }]}
          name={["firstname"]}
        >
          <Input placeholder={t("firstname")} />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: t("lastNameRequired") }]}
          name={["lastname"]}
        >
          <Input placeholder={t("lastname")} />
        </Form.Item>
        <Form.Item name={["company"]}>
          <Input placeholder={t("company")} />
        </Form.Item>
        <Form.Item name={["phone"]} rules={[{ pattern: /^\+?[0-9]*$/, message: t("wrongPhone") }]}>
          <Input placeholder={t("tel")} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddGuest;
