import React, { useEffect, useRef, useState } from "react";
import qs from "qs";
import classNames from "classnames/bind";
import packageJson from "../../package.json";
import styles from "./Layout.less";
import PanelContainer from "containers/PanelContainer";
import PlanningContainer from "containers/PlanningContainer";

const cx = classNames.bind(styles);

const Layout = () => {
  const { logger } = global;

  const [containerToShow, setContainerToShow] = useState();
  const [organizer, setOrganizer] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [token, setToken] = useState("");
  const [appId, setAppId] = useState("");
  const [initialFilter, setInitialFilter] = useState({});

  useEffect(() => {
    let params = qs.parse(window.location.href.split("?")[1]);
    logger.debug("Parameters retrieved", params);
    if (params.showPlanning === "true") {
      logger.debug("Must display room planning");
      let filter = {};
      setToken(params.token);
      setStart(params.start);
      setEnd(params.end);
      setAppId(params.appId);
      filter.attendeesCount = parseInt(params.attendeesCount);
      if (params.floor) filter.floor = parseInt(params.floor);
      if (params.equipments) filter.equipments = JSON.parse(params.equipments);
      if (params.serviceCategories)
        filter.serviceCategories = JSON.parse(params.serviceCategories);
      setInitialFilter(filter);

      setContainerToShow("planning");
    } else {
      logger.debug("Must display summary");
      setContainerToShow("panel");
    }
  }, []);

  return (
    <>
      <div className={cx("main-layout")}>
        {containerToShow === "planning" && (
          <PlanningContainer
            startDate={start}
            endDate={end}
            organizer={organizer}
            token={token}
            appId={appId}
            initialFilter={initialFilter}
          />
        )}
        {containerToShow === "panel" && <PanelContainer />}
      </div>
      <div className={cx("version")}>v{packageJson.version}</div>
    </>
  );
};

export default Layout;
