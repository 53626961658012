/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react";
import * as R from "ramda";
import moment from "moment";
import qs from "qs";
import { v4 as uuidV4 } from "uuid";
import StackTrace from "stacktrace-js";
import OfficeHelper from "../api/OfficeHelper";
import useSwizi from "./SwiziManager";
import requestsHelper from "./requestHelper";

const ADDIN_PROPERTY = "extras";
const equals = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

const useOffice = () => {
  const { logger } = global;
  const Office = window.Office;
  const [token, setToken] = useState();

  const [isReady, setIsReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [officeIsWorking, setOfficeIsWorking] = useState(false);
  const [officeIsLoading, setOfficeIsLoading] = useState(false);
  const [officeIsReady, setOfficeIsReady] = useState(false);
  const [officeIsError, setOfficeIsError] = useState(false);
  const [officeErrorMessage, setOfficeErrorMessage] = useState("");

  const [attendees, setAttendees] = useState([]);
  const [attendeesCount, setAttendeesCount] = useState(0);
  const [location, setLocation] = useState();
  const [organizer, setOrganizer] = useState();
  const [extras, setExtras] = useState();
  const [appId, setAppId] = useState("");
  const [itemId, setItemId] = useState();
  const [isRecurrentMeeting, setIsRecurrentMeeting] = useState(false);
  const [language, setLanguage] = useState("fr");
  const [roomRequests, setRoomRequests] = useState([]);
  const [delegation, setDelegation] = useState();
  const [previousInvitedGuests, setPreviousInvitedGuests] = useState([]);

  const [start, setStart] = useState(moment());
  const [end, setEnd] = useState(moment().add(1, "hour"));

  const currentSiteRef = useRef();

  const locationIsInitialized = useRef(false);

  const {
    swiziManager,
    rooms,
    floors,
    isAgent,
    timezone,
    filter,
    equipments,
    services,
    serviceCategories,
    domains,
    ticketTypes,
    availabilities,
    swiziIsWorking,
    swiziIsLoading,
    swiziIsReady,
    swiziIsError,
    swiziErrorMessage,
    swiziToken,
    currentSite,
    sites,
    allSitesRooms,
    showVisitors,
  } = useSwizi();

  const item = useRef();
  const attendeesPrev = useRef();

  useEffect(() => {
    currentSiteRef.current = currentSite;
  }, [currentSite]);

  useEffect(() => {
    logger.debug("Availabilities list change", availabilities);
  }, [availabilities]);

  // Retrieve user office  token
  const retrieveToken = useCallback(async () => {
    try {
      Office.context.mailbox.getCallbackTokenAsync(
        { isRest: true },
        async (result) => {
          setToken(result.value);
        }
      );
      const language = Office.context.displayLanguage.split("-")[0];
      setLanguage(language);
      logger.debug("Language : " + language);
    } catch (error) {
      console.error(error);
    }
  });

  /*
   *
   * OFFICE ACTIONS
   *
   */

  const addLocation = async (location) => {
    try {
      logger.debug("will add location", location);
      item.current.enhancedLocation.addAsync(
        [
          {
            id: location,
            type: "room",
          },
        ],
        (res) => {
          logger.debug("addLocation result", res);
        }
      );
    } catch (error) {
      logger.error("Error during add location", error);
    }
  };

  const clearLocation = async () => {
    try {
      logger.debug("will clear location");
      item.current.enhancedLocation.removeAsync(
        [
          {
            id: location.email,
            type: "room",
          },
        ],
        (res) => {
          logger.debug("removeLocation result", res);
        }
      );
    } catch (error) {
      logger.error("Error during add location", error);
    }
  };

  const updateStartEnd = async (newStart, newEnd) => {
    try {
      logger.debug("will update start/end", { newStart, newEnd });
      const { start, end } = await OfficeHelper.getSchedule(item.current);

      if (newStart > end) {
        await OfficeHelper.setEnd(item.current, newEnd);
        await OfficeHelper.setStart(item.current, newStart);
      } else {
        await OfficeHelper.setStart(item.current, newStart);
        await OfficeHelper.setEnd(item.current, newEnd);
      }
    } catch (error) {
      logger.error("Error during updateStartEnd", error);
    }
  };

  /*
   *
   * HANDLERS OFFICES
   *
   */

  const handleLocationChanged = useCallback(async () => {
    let location = await OfficeHelper.getLocation(item.current, allSitesRooms);
    logger.debug("Location has changed", { location });

    if (location && location.siteId !== currentSiteRef.current?.id) {
      swiziManager.updateSite(location.siteId);
    }
    if (!location && currentSiteRef.current?.id) {
      swiziManager.updateSite(currentSiteRef.current?.id);
    }
    setLocation(location);
    return location;
  });

  const handleScheduleChanged = useCallback(async () => {
    let { start, end } = await OfficeHelper.getSchedule(item.current);
    let s = moment(start);
    let e = moment(end);

    setStart(s);
    setEnd(e);

    swiziManager.updateSchedule(s, e);
  });

  const handleAttendeesChanged = useCallback(async (data, currentOrganizer) => {
    if (!currentOrganizer)
      currentOrganizer = Office.context.mailbox.userProfile.emailAddress;
    let lAttendees = await OfficeHelper.getAllRecipients(item.current);
    let count = 1; // set 1 attendee for organizer

    lAttendees = lAttendees
      .filter((att) => {
        if (rooms.find((r) => r?.email === att.emailAddress)) {
          // organizer is room
          count--;
          if (count < 1) count = 1;
          return false;
        }
        if (currentOrganizer.toUpperCase() === att.emailAddress.toUpperCase()) {
          return false;
        }
        count++;

        // domains filter
        if (!domains || domains.length === 0) return true;
        let userDomain = (att.emailAddress || "").split("@")[1];
        return !domains.includes(userDomain);
      })
      .map((g) => g.emailAddress);

    setAttendeesCount(count);
    swiziManager.updateAttendeesCountChange(count);
    let nlAttendees = [];
    if (lAttendees.length > 0) {
      nlAttendees = await swiziManager.getSwiziUsersFromEmail(lAttendees);
      logger.debug("nlAttendees", nlAttendees);
    }

    nlAttendees = nlAttendees.map((att) =>
      Object.assign(att, { existsAsVisitor: att.id !== undefined })
    );

    logger.debug("attendee list", nlAttendees);

    if (!equals(nlAttendees, attendeesPrev.current)) {
      setAttendees(nlAttendees);
      attendeesPrev.current = lAttendees;
    }
  });

  const manageOfficeEventSubscriptions = useCallback(async () => {
    item.current.addHandlerAsync(
      Office.EventType.RecipientsChanged,
      handleAttendeesChanged
    );
    item.current.addHandlerAsync(
      Office.EventType.AppointmentTimeChanged,
      handleScheduleChanged
    );
    item.current.addHandlerAsync(
      Office.EventType.EnhancedLocationsChanged,
      handleLocationChanged
    );
  });

  /*
   *
   * MANAGER CALLBACKS
   *
   */

  const retrieveItemInfos = useCallback(async () => {
    try {
      setOfficeIsWorking(true);
      setOfficeIsLoading(true);
      setOfficeIsReady(false);
      logger.debug("Start retrieving info from meeting");
      item.current = Office.context.mailbox.item;
      let lConnectedUser = Office.context.mailbox.userProfile.emailAddress;
      let lItemId = await OfficeHelper.getItemId(item.current);
      let lIsRecurrentMeeting = await OfficeHelper.isRecurrentMeeting(
        item.current
      );
      let lDelegation = await OfficeHelper.delegationProperties(
        item.current,
        lConnectedUser
      );
      let lOrganizer = lDelegation?.owner || lConnectedUser;
      setDelegation(lDelegation);
      const lLocation = await handleLocationChanged();
      setOrganizer(lOrganizer);

      await handleAttendeesChanged(undefined, lOrganizer);
      await handleScheduleChanged();
      setItemId(lItemId);
      setIsRecurrentMeeting(lIsRecurrentMeeting);

      manageOfficeEventSubscriptions();
      setOfficeIsReady(true);

      // Retrieve extras if existing, set default empty extra if none

      let customPropsExtras = await OfficeHelper.getCustomProp(
        item.current,
        ADDIN_PROPERTY
      );
      logger.debug("customProperties from event", customPropsExtras);
      if (!customPropsExtras) {
        customPropsExtras = {
          uid: uuidV4(),
        };
        await OfficeHelper.setCustomProp(
          item.current,
          customPropsExtras,
          ADDIN_PROPERTY
        );
      }

      const locationEmailFromExtra = customPropsExtras.tmpValue?.locations
        ? customPropsExtras.tmpValue.locations[0]?.email
        : undefined;
      const locationSelectedEmail = lLocation?.email;

      logger.debug("compare seleted/extra locations", {
        locationEmailFromExtra,
        locationSelectedEmail,
      });
      let lExtras = {
        invitedGuests: (customPropsExtras?.tmpValue?.invitedGuests || []).map(
          (g) => g.email || g
        ),
        isUnderConstruction:
          customPropsExtras?.tmpValue?.underConstruction || false,
        requests:
          locationSelectedEmail &&
          locationSelectedEmail === locationEmailFromExtra
            ? customPropsExtras?.tmpValue?.requests || []
            : [],
        locations: [location],
      };

      if (lExtras.invitedGuests.length > 0)
        lExtras.invitedGuests = await swiziManager.getSwiziUsersFromEmail(
          lExtras.invitedGuests
        );

      setExtras(lExtras);
      logger.debug("Extras load from meeting", lExtras);
      locationIsInitialized.current = true;
      setOfficeIsLoading(false);
    } catch (err) {
      let str = await StackTrace.fromError(err);
      logger.error(err.message + " " + str);
      setOfficeIsError(true);
    } finally {
      setOfficeIsWorking(false);
    }
  });

  /*
   *
   * EFFECTS
   *
   */

  useEffect(() => {
    setIsLoading(officeIsLoading || swiziIsLoading);
  }, [swiziIsLoading, officeIsLoading]);

  useEffect(() => {
    logger.debug(`officeIsReady=${officeIsReady} swiziIsReady=${swiziIsReady}`);
    setIsReady(officeIsReady && swiziIsReady);
  }, [officeIsReady, swiziIsReady]);

  useEffect(() => {
    setIsWorking(officeIsWorking || swiziIsWorking);
  }, [swiziIsWorking, officeIsWorking]);

  useEffect(() => {
    if (officeIsError || swiziIsError) {
      setIsError(true);
      setErrorMessage(
        officeIsError
          ? officeErrorMessage
          : swiziIsError
          ? swiziErrorMessage
          : ""
      );
    } else {
      setIsError(false);
      setErrorMessage();
    }
  }, [swiziIsError, officeIsError, swiziErrorMessage, officeErrorMessage]);

  // token has changed, manage subscription to office events
  useEffect(() => {
    if (!token) return;
    setIsReady(false);
    if (token) {
      let params = qs.parse(window.location.href.split("?")[1]);
      setAppId(params.appId);
      swiziManager.initialize(token, params.appId);
    }
  }, [token]);

  useEffect(() => {
    if (swiziIsReady) retrieveItemInfos();
  }, [swiziIsReady]);

  useEffect(() => {
    if (locationIsInitialized.current && extras && !location) {
      logger.debug(
        "Extras found without location, must remove it",
        locationIsInitialized.current
      );
      setPreviousInvitedGuests(extras.invitedGuests || []);
      setExtras(
        Object.assign(extras, {
          invitedGuests: [],
          isUnderConstruction: false,
          requests: [],
          locations: [],
        })
      );
    }

    if (location) {
      const roomRequests = requestsHelper(
        ticketTypes,
        language
      ).getServicesTreeFromProducts(location.services);
      setRoomRequests(roomRequests);
    }

    if (locationIsInitialized.current && location) {
      if (previousInvitedGuests && previousInvitedGuests.length > 0) {
        const lExtras = Object.assign(extras, {
          invitedGuests: previousInvitedGuests,
          locations: [location],
        });
        setExtras(lExtras);
        logger.debug("Configure new location with extras", {
          location,
          previousInvitedGuests,
          extras,
        });
      }
    }
  }, [location, extras]);

  const showPlanning = async (height = 80, width = 70) => {
    let url =
      window.location.origin +
      `?showPlanning=true&appId=${appId}&organizer=${organizer}&start=${start.toISOString()}&end=${end.toISOString()}&attendeesCount=${
        filter.attendeesCount
      }&token=${swiziToken}`;

    if (filter.floor !== undefined) url += `&floor=${filter.floor}`;
    if (filter.equipments && filter.equipments.length > 0)
      url += `&equipments=${JSON.stringify(filter.equipments)}`;
    if (filter.serviceCategories && filter.serviceCategories.length > 0)
      url += `&serviceCategories=${JSON.stringify(filter.serviceCategories)}`;
    let dialog;

    logger.debug(`filter to transfer`, filter);
    Office.context.ui.displayDialogAsync(
      url,
      { height, width, displayInIframe: true },
      function (asyncResult) {
        logger.debug("status=" + asyncResult.status);
        if (asyncResult.status === Office.AsyncResultStatus.Failed) {
          logger.error("asyncResult.error.code =" + asyncResult.error.message);
          setOfficeIsError(true);
          setOfficeErrorMessage(
            "Erreur Outlook dans l'ouverture du planning. Erreur reçue : " +
              asyncResult.error.message
          );
        } else {
          dialog = asyncResult.value;
          dialog.addEventHandler(
            Office.EventType.DialogMessageReceived,
            (data) => {
              const message = data?.message || "";
              const [command, value] = message.split("#");
              const [room, start, end] = value.split("&");
              logger.debug("Planning dialog return ", {
                command,
                room,
                start,
                end,
              });
              dialog.close();

              if (command === "OpenRoom") {
                addLocation(room);
                updateStartEnd(start, end);
              }
            }
          );
        }
      }
    );
  };

  const createAGuest = async ({
    email,
    firstname,
    lastname,
    phone,
    company = "",
  }) => {
    let user = await swiziManager.createSwiziUser({
      email,
      firstname,
      lastname,
      company,
      phone,
    });

    if (!user) return false;
    else {
      handleAttendeesChanged();
      return true;
    }
  };

  const updateExtra = async ({
    underConstruction,
    invitedGuests,
    requests,
  }) => {
    let lExtras = {
      tmpValue: {
        invitedGuests,
        underConstruction,
        delegation,
        requests,
        owner: delegation?.owner || organizer,
        locations: [
          { email: location.email, id: location.email, name: location.label },
        ],
        lastUpdate: moment().toISOString(),
      },
    };
    setExtras(lExtras);
    logger.debug("Save new extras", lExtras);
    await OfficeHelper.setCustomProp(item.current, lExtras, ADDIN_PROPERTY);
    await OfficeHelper.markAppointmentAsModified(item.current);
  };

  const officeManager = {
    showPlanning,
    initialize: retrieveToken,
    addLocation,
    clearLocation,
    createAGuest,
    updateExtra,
  };

  return {
    isReady,
    isLoading,
    isWorking,
    isError,
    errorMessage,
    start,
    end,
    attendees,
    location,
    organizer,
    extras,
    timezone,
    appId,
    floors,
    filter,
    equipments,
    services,
    serviceCategories,
    ticketTypes,
    availabilities,
    roomRequests,
    isAgent,
    officeManager,
    swiziManager,
    attendeesCount,
    isRecurrentMeeting,
    currentSite,
    sites,
    showVisitors,
  };
};

export default useOffice;
