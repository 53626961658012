import {
  Input,
  Form,
  InputNumber,
  Space,
  Typography,
  Button,
  Alert,
} from "antd";
import { useEffect, useState } from "react";
import Tile from "views/Common/Tile/Tile";
import { useTranslation } from "react-i18next";
import { checkDelayRule } from "managers/requestHelper";

const { TextArea } = Input;
const UpdateServices = ({ onSubmit, infos, services, start }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [total, setTotal] = useState(infos?.formProps?.total);
  const [cancel, setCancel] = useState(false);

  const [noAttendees, setNoAttendees] = useState(
    infos?.formProps?.no_Attendees
  );

  const infoServices = services
    ?.find((s) => s?.id === infos?.id)
    ?.categories?.find((c) => c?.id === infos?.categoryId);

  const handleSubmit = async (values) => {
    const service = Object.entries(values.values).reduce(
      (acc, [key, value]) => {
        return [...acc, { key: key, value: value }];
      },
      []
    );
    onSubmit(
      values,
      service,
      infoServices,
      total,
      noAttendees,
      infos?.id,
      cancel
    );
  };

  return (
    <Form
      onFinish={handleSubmit}
      form={form}
      onValuesChange={(_, values) => {
        const value = infoServices?.form?.elements
          ?.filter((e) => e.type === "NUMBER")
          ?.reduce(
            (acc, curr) =>
              acc + (curr.fee || 0) * (values.values[curr.key] || 0),
            0
          );
        const noAttendees = infoServices?.form?.elements
          ?.filter((e) => e.type === "NUMBER")
          .reduce((acc, curr) => acc + (values.values[curr.key] || 0), 0);
        setNoAttendees(noAttendees);
        setTotal(Math.round(value * 100) / 100);
      }}
      initialValues={{
        values: infos?.formProps?.values.reduce((acc, info) => {
          return { ...acc, [info.key]: info.value };
        }, {}),
      }}
    >
      {!checkDelayRule(start, infoServices?.form?.orderNotice) && (
        <Alert
          message={
            <Typography.Text strong>{t("modifyOutOfTime")}</Typography.Text>
          }
          type="error"
          style={{ marginBottom: "8px" }}
        />
      )}

      {!checkDelayRule(start, infoServices?.form?.cancelNotice) && (
        <Alert
          style={{ marginBottom: "8px" }}
          message={
            <Typography.Text strong>{t("deleteOutOfTime")}</Typography.Text>
          }
          type="error"
        />
      )}

      <div style={{ overflow: "auto", height: "400px", padding: "0 8px" }}>
        <Space
          style={{ width: "100%", marginBottom: "16px" }}
          direction="vertical"
          size="small"
        >
          {infoServices?.form?.elements?.map((element, index) => (
            <div key={index}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography.Text strong>{element?.name}</Typography.Text>
                {element?.type === "NUMBER" && (
                  <Typography.Text strong>
                    {element?.fee.toFixed(2)} {infoServices?.form?.currency}
                  </Typography.Text>
                )}
              </div>

              {element?.type === "NUMBER" ? (
                <Tile>
                  <Typography.Text strong>{t("amount")}</Typography.Text>
                  <Form.Item
                    noStyle
                    name={["values", element?.key]}
                    rules={[
                      {
                        required: element?.required,
                        message: t("obligatory"),
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      defaultValue={0}
                    />
                  </Form.Item>
                </Tile>
              ) : (
                <Form.Item
                  name={["values", element?.key]}
                  rules={[
                    {
                      required: element?.required,
                      message: t("obligatory"),
                    },
                  ]}
                >
                  <TextArea
                    maxLength={100}
                    style={{
                      height: 100,
                      resize: "none",
                    }}
                  />
                </Form.Item>
              )}
              <Typography.Text strong type="secondary">
                {element?.description}
              </Typography.Text>
            </div>
          ))}
        </Space>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          margin: "8px 0",
        }}
      >
        <Typography.Text strong style={{ textTransform: "uppercase" }}>
          {t("total")}
        </Typography.Text>
        <Typography.Text strong>{parseInt(total).toFixed(2)}€</Typography.Text>
      </div>
      <div
        style={{
          marginTop: "8px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          style={{
            marginRight: "8px",
            whiteSpace: "pre-wrap",
            height: "auto",
            width: "150px",
          }}
          disabled={!checkDelayRule(start, infoServices?.form?.cancelNotice)}
          type="danger"
          onClick={() => {
            form.submit();
            setCancel(true);
          }}
        >
          {t("cancelThisPresta")}
        </Button>
        <Button
          style={{ whiteSpace: "pre-wrap", height: "auto", width: "150px" }}
          disabled={!checkDelayRule(start, infoServices?.form?.orderNotice)}
          type="secondary"
          onClick={() => {
            form.submit();
          }}
        >
          {t("updateThisPresta")}
        </Button>
      </div>
    </Form>
  );
};

export default UpdateServices;
